import { createContext, useContext } from "react"
import { useRef, useEffect, useState } from "react"

const AppContext = createContext()

export const AppWrapper = ({ children }) => {
  const [factionChosen, setFactionChosen] = useState(false)
  const [videoLoadedCount, setVideoLoadedCount] = useState(0)
  const [audioLoadedCount, setAudioLoadedCount] = useState(0)

  let sharedState = {
    factionChosen,
    setFactionChosen,
    videoLoadedCount,
    setVideoLoadedCount,
    audioLoadedCount,
    setAudioLoadedCount
  }

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
