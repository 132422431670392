import * as Sentry from "@sentry/nextjs"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV

const environment = SENTRY_ENV || "development"
const envToUse = ["staging", "main"]

if (envToUse.includes(environment)) {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://7a1275fff83d4bfa81e53959adda86f5@o1247172.ingest.sentry.io/6407003",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: SENTRY_ENV || "development",
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  })
}
