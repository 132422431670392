import "../styles/global.scss"
import { AppWrapper } from "../context/state"
import { ThemeProvider } from "next-themes"
import type { AppProps } from "next/app"

export default function App({ Component, pageProps }: AppProps) {
  return (
    <AppWrapper>
      <ThemeProvider defaultTheme="light" attribute="class">
        <Component {...pageProps} />
      </ThemeProvider>
    </AppWrapper>
  )
}
